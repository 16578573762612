import React from "react"
import Layout from '../components/layout'
import SEO from "../components/seo"

import {Container, Row, Col} from "react-bootstrap"

const WhatToBuy = () => (
  <Layout>
  <SEO title="What to buy" keyword={['Woodwork in hindi','Wood Selection','Lakdi ka chunao']} />
  <Container>
    <center><h2>Best Home Products</h2></center>
    <Row>
      <Col lg={4}>
      The 10 Best L-Shape Sofas of 2021
      </Col>
      <Col lg={4}>
        <p>Home Product खरीदने के लिए Top 10 Website</p>
        <div className="embed-responsive embed-responsive-16by9">
        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/EOxjSlpGXc8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
        </div>
      </Col>
      <Col lg={4}>
      The 10 Best L-Shape Sofas of 2021
      </Col>
    </Row>
    <Row>
    <Col lg={6}>
       White Kitchen Countertop
      </Col>
      <Col lg={6}>
        <p><strong>G5 granite</strong></p>
        <div className="embed-responsive embed-responsive-16by9">
        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/-JY9dfVo5dQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
        </div>
      </Col>
    </Row>
    <Row>
    <Col lg={6}>
      Thoughend glass in Kitchen
      </Col>
      <Col lg={6}>
        <p><strong>Backpainted glass</strong></p>
        <div className="embed-responsive embed-responsive-16by9">
        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/D_nwJL-w82w" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
      <p><b>Workwork Budget Planning</b></p>
      </Col>
    </Row>
  </Container>
  
</Layout>
  )

  
export default WhatToBuy